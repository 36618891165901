body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.top-buffer 
{ margin-top:20px; }

.footer{
  background-color: #E83556;
  margin:10px auto;
  padding: 40px 0px 20px 0px;
}

.icon {
  margin: 0px 10px;
}

.circle
    {
    width:100px;
    height:100px;
    border-radius:50%;
    font-size:18px;
    text-align: center;
    line-height: 100px;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
}

.buttonResume {
  background-color: #E83556;
  text-decoration: none;
  display: inline-block;
  color: #FFFFFF;
  transition: filter 0.3s ease 0s;
  -webkit-transition: filter 0.3s ease 0s;
  -moz-transition: filter 0.3s ease 0s;
  -ms-transition: filter 0.3s ease 0s;
  -o-transition: filter 0.3s ease 0s;
}

.buttonResume:hover {
  color: #FFFFFF;
  text-decoration: none;
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}

.buttonProjects {
  background-color: #61D0D4;
  text-decoration: none;
  display: inline-block;
  color: #FFFFFF;
  transition: filter 0.3s ease 0s;
  -webkit-transition: filter 0.3s ease 0s;
  -moz-transition: filter 0.3s ease 0s;
  -ms-transition: filter 0.3s ease 0s;
  -o-transition: filter 0.3s ease 0s;
}

.buttonProjects:hover {
  color: #FFFFFF;
  text-decoration: none;
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}

.buttonSkills {
  background-color: #D1C926;
  text-decoration: none;
  display: inline-block;
  color: #FFFFFF;
  transition: filter 0.3s ease 0s;
  -webkit-transition: filter 0.3s ease 0s;
  -moz-transition: filter 0.3s ease 0s;
  -ms-transition: filter 0.3s ease 0s;
  -o-transition: filter 0.3s ease 0s;
}

.buttonSkills:hover {
  color: #FFFFFF;
  text-decoration: none;
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}

.circleCV
    {
    width:200px;
    height:200px;
    border-radius:100px;
    font-size:20px;
    text-align: center;
    line-height: 200px;
    -webkit-border-radius:100px;
    -moz-border-radius:100px;
    -ms-border-radius:100px;
    -o-border-radius:100px;
}

.experience {
  background-color: #E83556;
  text-decoration: none;
  display: inline-block;
  color: #FFFFFF;
  margin: 10px;
}

.circleCoursework
    {
    width:120px;
    height:120px;
    border-radius:50%;
    font-size:20px;
    text-align: center;
    line-height: 120px;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
}

.coursework {
  background-color: #61D0D4;
  text-decoration: none;
  display: inline-block;
  color: #FFFFFF;
  margin: 10px;
}

.experienceRow {
  margin: 10px;
  font-size: 16px;
  padding: 10px;
}

.projectHeading {
  margin-left: 10px;
  padding: 40px 40px;
  border-bottom: 1px inset grey;
}

.projectRow {
  margin: 50px 0 50px 0;
  padding: 20px 40px;
  border-bottom: 1px inset grey;
  font-size: 16px;
}

.projectImgContainer {
  height: 250px;
}

.projectImg {
  width: inherit;
  height: inherit;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.page-enter {
  opacity: 0.01;
  transform:  translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform:  translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  transform:  translateX(100%);
  transition: all 300ms ease-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
